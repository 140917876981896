import * as Sentry from "@sentry/react";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";

import App from "./app";
import { Providers } from "./components/providers";
import ApplicationStoreProvider from "./contexts/application-store-context";
import { AuthProvider } from "./contexts/auth-provider";
import { SettingsProvider } from "./contexts/settings-context";

Sentry.init({
  dsn: "https://8066db21e9ba550dca64730fd401bf86@sentry.signalos.tech/15",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        /^https:\/\/api.parkiza.dev.signalos\.app/,
        /^https:\/\/api.parkiza.prod.signalos\.app/,
        // OLD URLS
        "https://parking-api.signalos.app",
        "https://test-parking-api.signalos.app",
      ],
    }),
    Sentry.feedbackIntegration({
      colorScheme: "system",
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api.parkiza.dev.signalos\.app/,
    /^https:\/\/api.parkiza.prod.signalos\.app/,
    // OLD URLS
    "https://parking-api.signalos.app",
    "https://test-parking-api.signalos.app",
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.PROD ? "production" : "development",
  enabled: import.meta.env.PROD,
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception && event.event_id) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //   }

  //   return event;
  // },
});

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ApplicationStoreProvider>
          <AuthProvider>
            <SettingsProvider>
              <Providers>
                <App />
                <Toaster
                  position="top-center"
                  toastOptions={{
                    style: {
                      wordBreak: "break-word",
                    },
                  }}
                />
              </Providers>
            </SettingsProvider>
          </AuthProvider>
        </ApplicationStoreProvider>
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root"),
);
