import { Box, Divider, Drawer, List, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";
import { useSiteEvChargersModuleAccess } from "src/features/site-ev-chargers/hooks/api/use-site-ev-chargers-module-access";
import { getRoutes as getSiteRoutes } from "src/features/site/constants/routes";
import { useTenantEvChargersModuleAccess } from "src/features/tenant-ev-chargers/hooks/api/use-tenant-ev-chargers-module-access";
import { getRoutes } from "src/features/tenant/constants/routes";
import useStore from "src/hooks/use-store";
import { ViewContext } from "src/store/user-store";
import { Route } from "src/types/interfaces/route";
import { Scrollbar } from "../atoms/scrollbar";
import { SidebarItem } from "../molecules/sidebar/sidebar-item";
import { OrganizationSelector } from "./organization-selector";
import { SiteSelector } from "./site-selector";

interface SidebarProps {
  onPin: () => void;
  pinned: boolean;
}

const workAreaSelector = (viewContext: ViewContext) => {
  switch (viewContext) {
    case "site":
      return <SiteSelector />;

    case "tenant":
      return <OrganizationSelector />;

    default:
      return null;
  }
};

const getRoutesForViewContext = (
  currentViewContext: ViewContext,
  hasAccessToEVChargers: boolean,
) => {
  switch (currentViewContext) {
    case "site":
      return getSiteRoutes(hasAccessToEVChargers);

    case "tenant":
      return getRoutes(hasAccessToEVChargers);
  }
};

const SidebarComponent: FC<SidebarProps> = props => {
  const { t } = useTranslation();
  const { pinned } = props;
  const { pathname } = useLocation();
  const { user } = useStore();

  const { data: siteEvChargersModuleAccess } = useSiteEvChargersModuleAccess();
  const { data: tenantEvChargersModuleAccess } = useTenantEvChargersModuleAccess();

  const sections = useMemo(
    () =>
      getRoutesForViewContext(
        user.viewContext,
        user.viewContext === "site"
          ? siteEvChargersModuleAccess?.hasAccessToEVChargers
          : tenantEvChargersModuleAccess?.hasAccessToEVChargers,
      ),
    [
      user.viewContext,
      siteEvChargersModuleAccess?.hasAccessToEVChargers,
      tenantEvChargersModuleAccess?.hasAccessToEVChargers,
    ],
  );
  const routes = useMemo(() => sections.flatMap(route => route.routes), [sections]);

  const [activeRoute, setActiveRoute] = useState<Route | null>(null);

  useEffect(() => {
    routes.forEach(route => {
      const active = matchPath({ path: route.href, end: false }, pathname);

      if (active) {
        setActiveRoute(route);
      }
    });
  }, [pathname, routes]);

  return (
    <Drawer
      open
      variant="permanent"
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          height: "calc(100% - 64px)",
          overflowX: "hidden",
          top: 64,
          zIndex: 1,
          transition: "width 250ms ease-in-out",
          width: pinned ? 270 : 73,
          "& .simplebar-content": {
            height: "100%",
          },
          "&:hover": {
            width: 270,
            "& span, p": {
              display: "flex",
            },
          },
        },
      }}>
      <Scrollbar
        style={{
          display: "flex",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 2,
            pt: 2.5,
            pb: 7,
          }}>
          <Box
            sx={{
              mb: 3,
            }}>
            {workAreaSelector(user.viewContext)}
          </Box>

          <List disablePadding>
            {sections.map((section, index) => (
              <Box key={section.title}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    mb: 1,
                  }}
                  color="text.secondary">
                  {t(section.title)}
                </Typography>
                {section.routes.map(route => (
                  <Box key={route.id}>
                    <SidebarItem
                      key={route.title}
                      href={route.href}
                      active={activeRoute?.title === route.title}
                      external={route.external}
                      title={route.title}
                      pinned={pinned}
                      id={route?.id}
                      {...route}
                    />
                  </Box>
                ))}
                {index !== sections.length - 1 && <Divider sx={{ mt: 2, mb: 1.4 }} />}
              </Box>
            ))}
          </List>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};

export const Sidebar = observer(SidebarComponent);
